@use '~modern-normalize/modern-normalize';
@use '~@mezzanine-ui/core' as mzn-core;
@use '~@mezzanine-ui/react-hook-form-core' as mzn-rhf-core;
@use '~@mezzanine-ui/system' as mzn-system;
@use '~@mezzanine-ui/system/spacing' as mzn-spacing;
@use '~@mezzanine-ui/system/z-index' as mzn-z-index;
@use '~@mezzanine-ui/system/palette' as palette;

:root {
  @include mzn-core.styles();
  @include mzn-rhf-core.styles();
  @include mzn-system.common-variables();
  @include mzn-spacing.variables();
  @include mzn-z-index.variables();
  @include palette.variables('light');

  --mzn-typography-font-family: PingFang TC, Microsoft JhengHei, serif,
    sans-serif;

  // h1
  --mzn-typography-h1-font-weight: 600;
  --mzn-typography-h1-font-size: 32px;
  --mzn-typography-h1-line-height: 48px;
  --mzn-typography-h1-letter-spacing: 4px;
  // h2
  --mzn-typography-h2-font-weight: 600;
  --mzn-typography-h2-font-size: 24px;
  --mzn-typography-h2-line-height: 36px;
  --mzn-typography-h2-letter-spacing: 2px;
  // h3
  --mzn-typography-h3-font-weight: 500;
  --mzn-typography-h3-font-size: 22px;
  --mzn-typography-h3-line-height: 32px;
  --mzn-typography-h3-letter-spacing: 2px;
  // h4
  --mzn-typography-h4-font-weight: 500;
  --mzn-typography-h4-font-size: 18px;
  --mzn-typography-h4-line-height: 28px;
  --mzn-typography-h4-letter-spacing: 1px;
  // h5
  --mzn-typography-h5-font-weight: 500;
  --mzn-typography-h5-font-size: 15px;
  --mzn-typography-h5-line-height: 24px;
  --mzn-typography-h5-letter-spacing: 0px;
  // h6
  --mzn-typography-h6-font-weight: 500;
  --mzn-typography-h6-font-size: 13px;
  --mzn-typography-h6-line-height: 20px;
  --mzn-typography-h6-letter-spacing: 0px;
  // button1
  --mzn-typography-button1-font-weight: 500;
  --mzn-typography-button1-font-size: 15px;
  --mzn-typography-button1-line-height: 40px;
  --mzn-typography-button1-letter-spacing: 2px;
  // button2
  --mzn-typography-button2-font-weight: 500;
  --mzn-typography-button2-font-size: 15px;
  --mzn-typography-button2-line-height: 32px;
  --mzn-typography-button2-letter-spacing: 2px;
  // button3
  --mzn-typography-button3-font-weight: 500;
  --mzn-typography-button3-font-size: 13px;
  --mzn-typography-button3-line-height: 24px;
  --mzn-typography-button3-letter-spacing: 1px;
  // input1
  --mzn-typography-input1-font-weight: 400;
  --mzn-typography-input1-font-size: 15px;
  --mzn-typography-input1-line-height: 40px;
  --mzn-typography-input1-letter-spacing: 1px;
  // input2
  --mzn-typography-input2-font-weight: 400;
  --mzn-typography-input2-font-size: 15px;
  --mzn-typography-input2-line-height: 32px;
  --mzn-typography-input2-letter-spacing: 1px;
  // input3
  --mzn-typography-input3-font-weight: 400;
  --mzn-typography-input3-font-size: 13px;
  --mzn-typography-input3-line-height: 24px;
  --mzn-typography-input3-letter-spacing: 0px;
  // body1
  --mzn-typography-body1-font-weight: 400;
  --mzn-typography-body1-font-size: 15px;
  --mzn-typography-body1-line-height: 24px;
  --mzn-typography-body1-letter-spacing: 0px;
  // body2
  --mzn-typography-body2-font-weight: 400;
  --mzn-typography-body2-font-size: 13px;
  --mzn-typography-body2-line-height: 20px;
  --mzn-typography-body2-letter-spacing: 0px;
  // caption
  --mzn-typography-caption-font-weight: 400;
  --mzn-typography-caption-font-size: 12px;
  --mzn-typography-caption-line-height: 16px;
  --mzn-typography-caption-letter-spacing: 0px;

  --mzn-color-primary: #0a72b4;
  --mzn-color-primary-light: #5a91b4;
  --mzn-color-primary-dark: #035392;
  --mzn-color-primary-hover-bg: rgba(10, 114, 180, 0.1);
  --mzn-color-primary-active-bg: rgba(90, 145, 180, 0.2);
  --mzn-color-primary-select-bg: rgba(90, 145, 180, 0.2);
  --mzn-color-secondary: #555659;
  --mzn-color-secondary-light: #686a6c;
  --mzn-color-secondary-dark: #16181a;
  --mzn-color-secondary-hover-bg: rgba(85, 86, 89, 0.1);
  --mzn-color-secondary-active-bg: rgba(104, 106, 108, 0.2);
  --mzn-color-secondary-select-bg: rgba(104, 106, 108, 0.2);
  --mzn-color-error: #ab0c0c;
  --mzn-color-error-light: #c40d0d;
  --mzn-color-error-dark: #910a0a;
  --mzn-color-error-hover-bg: rgba(171, 12, 12, 0.1);
  --mzn-color-error-active-bg: rgba(196, 13, 13, 0.2);
  --mzn-color-error-select-bg: rgba(196, 13, 13, 0.2);
  --mzn-color-warning: #c25700;
  --mzn-color-warning-light: #db6200;
  --mzn-color-warning-dark: #a84b00;
  --mzn-color-warning-hover-bg: rgba(194, 87, 0, 0.1);
  --mzn-color-warning-active-bg: rgba(219, 98, 0, 0.2);
  --mzn-color-warning-select-bg: rgba(219, 98, 0, 0.2);
  --mzn-color-success: #2f7e08;
  --mzn-color-success-light: #38960a;
  --mzn-color-success-dark: #256306;
  --mzn-color-success-hover-bg: rgba(56, 150, 10, 0.1);
  --mzn-color-success-active-bg: rgba(47, 126, 8, 0.2);
  --mzn-color-success-select-bg: rgba(47, 126, 8, 0.2);
  --mzn-color-bg: #f1f2f4;
  --mzn-color-surface: #fefefe;
  --mzn-color-action-active: #0a72b4;
  --mzn-color-action-inactive: #777b80;
  --mzn-color-action-disabled: #bcbcbc;
  --mzn-color-action-disabled-bg: #e5e5e5;
  --mzn-color-text-primary: #393a3c;
  --mzn-color-text-secondary: #8e8e8e;
  --mzn-color-text-disabled: #bcbcbc;
  --mzn-color-border: #dfdfdf;
  --mzn-color-divider: #d9d9d9;
}

html {
  -webkit-text-size-adjust: 100%;
  font-family: var(--mzn-typography-font-family);
  line-height: 1.5;
  tab-size: 4;
  scroll-behavior: smooth;
}

body {
  font-family: inherit;
  line-height: inherit;
  margin: 0;
  width: 100vw;
}

*::placeholder {
  color: #d2d2d2 !important;
}
